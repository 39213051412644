import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SliceZone from "../components/SliceZone";

export const WhySmartwealthPage = ({ data }) => {
  if (!data) return null;
  const header = data.prismicHeaderitem || {};
  const footer = data.prismicFooteritem || {};
  const { lang, type, url } = header || {};
  const alternateLanguages = header.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const whysmartwealth = data.prismicWhysmartwealth || {};

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={true}
    >
      <SliceZone slices={whysmartwealth.data.body} />
    </Layout>
  );
};

export const query = graphql`
  query WhySmartwealthQuery($lang: String) {
    prismicWhysmartwealth(lang: { eq: $lang }) {
      lang
      url
      type
      data {
        body {
          ... on PrismicWhysmartwealthBodyHeroSection {
            id
            slice_type
            primary {
              title {
                text
              }
              subtitle {
                raw
              }
              button {
                text
              }
              button_link {
                url
              }
              background_image {
                url
              }
              pulse_animation
            }
          }
          ... on PrismicWhysmartwealthBodyLargeTestimonialSection {
            id
            slice_type
            primary {
              title1 {
                text
              }
              text_button {
                text
              }
              button_link {
                url
              }
              text2 {
                text
              }
              text1 {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
              section_image {
                url
              }
              button1 {
                text
              }
            }
          }
          ... on PrismicWhysmartwealthBodySmalltestimonialSection {
            id
            primary {
              image {
                url
                alt
              }
              reversed
              section_name {
                text
              }
              section_number {
                text
              }
              text {
                text
              }
              title {
                text
              }
              buttontext {
                text
              }
              button_link {
                url
              }
              isbutton
              person_name {
                text
              }
              person_position {
                text
              }
            }
            slice_type
          }
          ... on PrismicWhysmartwealthBodyBeatperformanceSection {
            id
            slice_type
            primary {
              section_text {
                text
              }
              section_number {
                text
              }
              section_heading {
                text
              }
            }
          }
          ... on PrismicWhysmartwealthBodyWhysmartwealthSection {
            id
            slice_type
            primary {
              section_number {
                text
              }
              section_name {
                text
              }
              section_heading {
                text
              }
              button_text {
                text
              }
              button_link {
                url
              }
            }
            items {
              point_item_text {
                text
              }
              point_item_bold {
                text
              }
            }
          }
          ... on PrismicWhysmartwealthBodyBestportfolioSection {
            id
            slice_type
            primary {
              title {
                text
              }
              text1 {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicWhysmartwealthBodyTextureSection {
            id
            slice_type
            primary {
              title {
                text
              }
              emailtext {
                text
              }
              buttontext {
                text
              }
            }
          }
          ... on PrismicWhysmartwealthBodyPhilosophiesSection {
            id
            slice_type
            primary {
              section_title {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
            }
            items {
              item_title {
                text
              }
              item_text {
                text
              }
            }
          }
          ... on PrismicWhysmartwealthBodyLosungSection {
            id
            slice_type
            primary {
              section_title {
                text
              }
              section_text {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
            }
            items {
              item_title {
                text
              }
              item_text {
                raw
              }
              logo {
                url
              }
            }
          }
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        products {
          product_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
  }
`;

export default WhySmartwealthPage;
